import { Injectable } from '@angular/core';
import { CountryEnum } from 'enums/country.enum';
import { ResponsableType } from 'enums/responsable.type.enum';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LocalStorageService } from './../guards/storage.service';

const canSync = () => map<any, boolean>(storage => storage?.country_selected?.id == CountryEnum.Brasil && (!!storage?.atendimento_portal || storage?.externo == 0))
const isAtendimentoPortal = () => map<any, boolean>(storage => !!storage.atendimento_portal)
const hasProfile = (profile: string) => map<any, boolean>(storage => {
    if (!!storage?.atendimento_portal) {
        return true
    }
    return !!storage[profile]
})
const hasProfiles = (...profile: string[]) => map<any, boolean>(storage => {
    if (!!storage?.atendimento_portal) {
        return true
    }
    return profile.some(p => !!storage[p])
})
const hasFunction = (type: ResponsableType) => map<any, boolean>(storage => {
    if (!!storage?.atendimento_portal) {
        return true
    }
    if (!!storage?.coordenador_conteudo) {
        return false
    }
    return storage?.funcoes?.some(funcao => funcao?.tipo == type)
})
const hasFunctions = (...type: ResponsableType[]) => map<any, boolean>(storage => {
    if (!!storage?.atendimento_portal) {
        return true
    }
    if (!!storage?.coordenador_conteudo) {
        return false
    }
    return storage?.funcoes?.some(funcao => type.includes(funcao.tipo))
})
const hasFunctionsOrProfiles = (...params: any) => map<any, boolean>(storage => {
    if (!!storage?.atendimento_portal) {
        return true
    }
    if (!!storage?.coordenador_conteudo) {
        return false
    }
    return params.some(p => !!storage[p]) || storage?.funcoes?.some(funcao => params.includes(funcao.tipo))
})
const hasFunctionStrict = (type: ResponsableType) => map<any, boolean>(storage => storage?.funcoes?.some(funcao => funcao.tipo == type))
const isAdventistSchool = () => map<any, boolean>(storage => {
    if (!!storage?.atendimento_portal || storage?.externo == 0) {
        return true;
    }
    return false;
});
enum Profiles {
    coordenador_conteudo = 'coordenador_conteudo',
    coordenador_avaliacao = 'coordenador_avaliacao',
    editor_avaliacao = 'editor_avaliacao',
    advisor = 'advisor',
    advisor_supervisor = 'advisor_supervisor'
}
@Injectable({
    providedIn: 'root',
})
export class PermissionsService {

    constructor(private localStorageService: LocalStorageService) { }

    public get isTecnicoAdministrativo$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(hasFunction(ResponsableType.Tecnico))
    }

    public get isRepresentanteComercial$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(hasFunction(ResponsableType.Comercial))
    }

    public get isOnlyRepresentanteComercial$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(hasFunctionStrict(ResponsableType.Comercial))
    }

    /**
     * @description Tecnico Administrativo ou Representante Comercial
     */
    public get isTecnicoAdmRepresentanteComercial$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(hasFunctions(ResponsableType.Tecnico, ResponsableType.Comercial))
    }

    /**
     * @description Coordenador Avaliacao ou Editor Avaliacao
     */
    public get isCoordenadorAvaliacaoEditorAvaliacao$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(filter(storage => !!storage[Profiles.coordenador_avaliacao] || !!storage[Profiles.editor_avaliacao]))
    }

    public get isCoordenadorConteudo$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(hasProfile(Profiles.coordenador_conteudo))
    }

    public get isCoordenadorAvaliacao$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(hasProfile(Profiles.coordenador_avaliacao))
    }

    public get isEditorAvaliacao$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(hasProfile(Profiles.editor_avaliacao))
    }

    public get isAtendimentoPortal$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(isAtendimentoPortal())
    }

    public get isAdvisor$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(hasProfile(Profiles.advisor))
    }

    public get isAdvisorSupervisor$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(hasProfile(Profiles.advisor_supervisor))
    }

    public get isAdvisorOrAdvisorSupervisor$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(hasProfiles(Profiles.advisor, Profiles.advisor_supervisor))
    }

    public get isTecnicoAdmOrAdvisor$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(
            hasFunctionsOrProfiles(ResponsableType.Tecnico, Profiles.advisor, Profiles.advisor_supervisor)
        )
    }

    public get isRepresentanteCPB$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(map<any, boolean>(storage => !!storage?.atendimento_portal || storage?.representante_tecnico_entidades?.some(r => !!r.campo_id && r.campo.uniao_id == 1)))
    }

    public get canSync$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(canSync())
    }

    public get isAdventistSchool$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(
            isAdventistSchool()
        );
    }

    public get isMarketingHispano$(): Observable<boolean> {
        return this.localStorageService.storage$.pipe(map<any, boolean>(storage => storage?.country_selected?.id != 1 && storage?.representante_tecnico_entidades?.filter(item => item.entidade_id !== null)))
    }
}
