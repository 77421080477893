import { Resp } from "@services/api.service"
import { Observable, of } from "rxjs"
import { catchError, map } from "rxjs/operators"

export function reduce(data: Object[]): Object {
    return data.reduce((acc, curr) => ({ ...acc, ...curr }), [])
}

export function someValue(obj: Object = {}): boolean {
    return !!Object.values(obj).some(v => !!v)
}

export function safeEmptyList<T>() {
    return (source: Observable<Resp<T[]>>): Observable<T[]> => source.pipe(
        catchError(() => of({ ret: 0, data: [] })),
        map((res) => res.ret === 1 ? res.data : [])
    )
}
