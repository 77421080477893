import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { hubConst } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from '@services/permissions.service';
import { utcToZonedTime } from 'date-fns-tz';
import { ResponsableType } from 'enums/responsable.type.enum';
import { Observable, Subject } from 'rxjs';
import packageJson from '../../package.json';
import { CountryEnum } from '../enums/country.enum';
import { DomainEnum } from '../enums/domain.enum';
import { StatusEnum } from '../enums/status.enum';
import { I18nService } from './core/service/i18n.service';
import { AuthGuard } from './guards/auth.guard';
import { LocalStorageService } from './guards/storage.service';
import { UsuarioService } from './services/usuario.service';
import { PaisActionsService } from './shared/service/pais-actions.service';
import { Sabado, Swal } from './utils';
import { setName } from './utils/set-name';


declare var gtag
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    viewProviders: [Title],
})
export class AppComponent {
    private readonly path_s3: any = hubConst.api.path_s3

    public readonly anoAtual = new Date()

    public readonly version: string = packageJson.version

    public readonly canSync$: Observable<boolean> = this.permissionsService.canSync$
    public readonly isAtendimentoPortal$: Observable<boolean> = this.permissionsService.isAtendimentoPortal$
    public readonly isTecnicoAdministrativo$: Observable<boolean> = this.permissionsService.isTecnicoAdministrativo$
    public readonly isTecnicoAdmRepresentanteComercial$: Observable<boolean> = this.permissionsService.isTecnicoAdmRepresentanteComercial$
    public readonly isCoordenadorConteudo$: Observable<boolean> = this.permissionsService.isCoordenadorConteudo$
    public readonly isCoordenadorAvaliacao$: Observable<boolean> = this.permissionsService.isCoordenadorAvaliacao$
    public readonly isCoordenadorAvaliacaoEditorAvaliacao$: Observable<boolean> = this.permissionsService.isCoordenadorAvaliacaoEditorAvaliacao$
    public readonly isTecnicoAdmOrAdvisor$: Observable<boolean> = this.permissionsService.isTecnicoAdmOrAdvisor$
    public readonly isAdvisorSupervisor$: Observable<boolean> = this.permissionsService.isAdvisorSupervisor$
    public readonly isRepresentanteCPB$: Observable<boolean> = this.permissionsService.isRepresentanteCPB$

    loading: boolean = true
    loadingText: string = ''
    public brazilianAdventistSchool: boolean = false

    public canChangeCountry: boolean = true
    title = 'hub-educacional';
    isLogin: boolean = false
    isSabado: boolean = false
    permissoes: any = []
    storage: any
    cpgp: boolean = false // Checa se a Permissão do Gerenciador do Portal está liberada
    mtvgp: number = 0 // Motivo do portal estar liberado ou não: 0 - normal; 1 - Sábado; 2 - manutenção
    user = {
        name: '',
        surname: '',
        initial: '',
        function: '',
        email: '',
        uuid: '',
        photo: ''
    }
    countries: any
    public country_changed = new Subject()
    private t_translate: any = []
    menuSelected: string = 'entidades'

    bgImg: string = ''
    isUserCampo: boolean = false
    isUserUniao: boolean = false

    t_titulo: string = 'HUB Educacional'

    constructor(
        private authGuard: AuthGuard,
        private router: Router,
        public storageService: LocalStorageService,
        public sabado: Sabado,
        private usuarioService: UsuarioService,
        private translate: TranslateService,
        private swal: Swal,
        private i18nService: I18nService,
        private titulo: Title,
        private paisActionsService: PaisActionsService,
        private permissionsService: PermissionsService,
        @Inject(DOCUMENT) private document: Document
    ) { }

    ngOnInit() {
        this.permissionsService.isOnlyRepresentanteComercial$.subscribe(resp => {
            if (resp) {
                this.router.navigate(['/usuarios'])
            }
        })

        this.setTitulo('')

        this.toggleLoading(false)
        this.paisActionsService.canChangeCountry$.subscribe(canChange => this.canChangeCountry = canChange)
        //verifica se o processo de login foi executado e o storage está gravado
        this.authGuard.login.subscribe(async (resp) => {
            this.isLogin = resp
            if (resp) {
                this.storage = this.storageService.storageUsuario

                if (typeof this.storage !== 'object') {
                    this.sair('login')
                }

                this.setBrazilianAdventistSchool()

                this.countries = this.storage.paises
                let initials = this.storage.country_selected ? this.storage.country_selected.initials : this.storage.paises[0].sigla
                if (this.storage.country_selected == null) {
                    this.setCountryStorage(initials)
                } else {
                    this.init()
                }

                await this.carregaPermissoes()
                // Analytics
                this.loadAnalytics()
                this.setTypeUser()
            }
        })

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                // this.loading = false
                this.menuSelected = event.url.split('/')[1]
                this.bgImg = (event.url == '/') ? 'bg-img' : ''

                setTimeout(() => {
                    if (this.storage != null) {
                        let timezone = this.storage && this.storage.country_selected.timezone ? this.storage.country_selected.timezone : 'America/Sao_Paulo'
                        let pais_id = this.storage && this.storage.country_selected.id != null ? this.storage.country_selected.id : 1
                        let hoje = utcToZonedTime(new Date(), timezone)
                        if (event.url != '/sabado' && this.sabado.verificaSabado(hoje, pais_id)) {
                            this.authGuard.setCPGPS(1)
                        }
                    }
                }, 500)
            }
        })

        this.authGuard.isSabado.subscribe((sabado) => {
            this.isSabado = sabado
        })
    }

    public get isHispanos(): boolean {
        return (
            this.storageService?.currentCountry?.id !== CountryEnum.Brasil
        )
    }

    public get nameUser(): string {
        return this.storage?.country_selected?.id == CountryEnum.Brasil ? this.user.name : this.user.surname
    }

    public validPageActivated(...route: [string]): string {
        return route.includes(this.menuSelected) ? 'menu-item-active' : ''
    }

    private init() {
        this.i18nService.change(this.storage?.country_selected.language || 'pt-BR') // seta a linguagem padrão do sistema
        this.translate.get([
            'geral.cancelar',
            'geral.sim',
            'geral.atencao',
            'geral.deseja-alterar-pais',
            'funcoes.atendimento-portal',
            'funcoes.coordenador-conteudo',
            'funcoes.coordenador-avaliacao',
            'funcoes.responsavel-legal',
            'funcoes.responsavel-tecnico',
            'funcoes.responsavel-comercial',
            'funcoes.advisor',
            'funcoes.advisor-supervisor',
            'geral.conta-vinculada',
            'geral.modelo',
            'geral.sistema',
            'geral.ultima-sincronizacao',
            'geral.ao-sair-seguro',
            'geral.sair-agora',
        ]).subscribe((text: any) => {
            this.t_translate = text

            this.user = {
                name: this.storage.nome,
                surname: this.storage.sobrenome,
                initial: this.storage.nome.substr(0, 1),
                function: this.getUserFunction(),
                email: this.storage.email,
                uuid: this.storage.uuid,
                photo: this.storage.photo != null ? (this.path_s3 + 'perfil/' + this.storage.photo) : null
            }
        })
    }

    setTitulo(title_page) {
        this.titulo.setTitle(`${hubConst.title}${title_page} | ${this.t_titulo}`)
    }

    public toggleLoading(show, text = '') {
        let time = (show == true) ? 0 : 500

        this.loadingText = text

        setTimeout(() => {
            this.loading = show
        }, time)
    }

    public setBrazilianAdventistSchool() {
        this.brazilianAdventistSchool = this.isBrazilianEA(this.storage?.country_selected?.id, this.storage?.externo) && this.storage?.atendimento_portal != StatusEnum.active
    }

    public isBrazilianEA(country, externo) {
        return (country === CountryEnum.Brasil && externo === 0)
    }

    getUserFunction() {
        let func = ''
        if (!!this.storage.atendimento_portal) {
            func = this.t_translate['funcoes.atendimento-portal']
        } else if (!!this.storage.coordenador_conteudo) {
            func = this.t_translate['funcoes.coordenador-conteudo']
        } else if (!!this.storage.coordenador_avaliacao) {
            func = this.t_translate['funcoes.coordenador-avaliacao']
        } else if (!!this.storage.advisor) {
            func = this.t_translate['funcoes.advisor']
        } else if (!!this.storage.advisor_supervisor) {
            func = this.t_translate['funcoes.advisor-supervisor']
        } else {
            if (this.storage.funcoes[0].tipo == ResponsableType.Tecnico) {
                func = this.t_translate['funcoes.responsavel-tecnico']
            } else if (this.storage.funcoes[0].tipo == ResponsableType.Comercial) {
                func = this.t_translate['funcoes.responsavel-comercial']
            }

        }

        return func
    }

    onChangeCountry(initials) {
        if (!this.canChangeCountry) {
            this.swal.confirmAlertCustom(
                this.t_translate['geral.atencao'],
                this.t_translate['geral.deseja-alterar-pais'],
                'info',
                this.t_translate['geral.sim'],
                this.t_translate['geral.cancelar'],
                { callback: () => this.setCountryStorage(initials) },
            )
        } else {
            this.setCountryStorage(initials)
        }
    }

    setCountryStorage(initials) {
        for (let country of this.countries) {
            if (initials == country.sigla) {
                this.storage.country_selected = {
                    'id': country.id,
                    'initials': initials,
                    'timezone': country.timezone,
                    'description': country.descricao,
                    'language': country.lingua,
                    'editoras': country.editoras,
                    'modelos': country.modelos,
                }//
                this.storageService.setStorageUsuario(this.storage);
                this.document.documentElement.lang = country.lingua
                this.country_changed.next(true)
                this.paisActionsService.setPais(this.storage.country_selected)
                this.init()
                break;
            }
        }
    }

    setName(name: string, surname: string): string {
        if (!!this.storage) {
            return setName(name, surname, this.storage.country_selected.id)
        }
        return ''
    }

    // tornamos publico o authGuard para as classes que instanciam o AppComponent
    getAuthGuard() {
        return this.authGuard
    }

    sair(url, user_id = null) {
        this.authGuard.limpaStorage(url, user_id)
    }

    onLogout() {
        let user_id = this.storage.access_token != null ? this.storage.usuario_id : null
        if (!user_id) {
            this.sair('/')
        } else {
            this.toggleLoading(true)
            this.usuarioService.getListOfDevicesGoogle(user_id).subscribe(
                (response) => {
                    this.toggleLoading(false)
                    let devices = response.ret == 1 ? response.data : null
                    this.confirmLogout(user_id, devices)
                },
                (error) => {
                    this.toggleLoading(false)
                    this.confirmLogout(user_id)
                    if (error.status == 401 && error._body == '["token_expired"]') {
                        this.sair('/')
                    }
                }
            )
        }
    }

    confirmLogout(user_id, devices = null) {
        let msg = ''

        let swal_large = false
        if (devices != null) {
            swal_large = true
            msg = this.t_translate['geral.conta-vinculada'] + '<br>'
            msg += '<br>' +
                '<table style="width: 50%; margin: 0 auto; text-align:center;">' +
                '<thead>' +
                '<tr>' +
                '<th>' + this.t_translate['geral.modelo'] + '</th>' +
                '<th>' + this.t_translate['geral.sistema'] + '</th>' +
                '<th>' + this.t_translate['geral.ultima-sincronizacao'] + '</th>' +
                '</tr>' +
                '</thead>' +
                '<tbody>'
            devices.forEach(element => {
                //msg += '<br>' + element.model + ' - ' + element.os + ' <small>(' + new Date(element.last_sync).toLocaleString() + ')</small>'
                let model = element.model != null && element.model != 'NULL' && element.model != 'null' ? element.model : '-'
                msg += '<tr><td>' + model + '</td><td>' + element.os + '</td><td>' + new Date(element.last_sync).toLocaleString() + '</td></tr>'
            })
            msg += '</tbody></table><br>'
        }
        msg += this.t_translate['geral.ao-sair-seguro']

        this.swal.confirmAlertCustom(this.t_translate['geral.atencao'],
            msg,
            'info',
            this.t_translate['geral.sair-agora'],
            this.t_translate['geral.cancelar'],
            { callback: () => this.sair('logout', user_id) },
            null,
            'html',
            swal_large
        )
    }

    private carregaPermissoes() {
        this.usuarioService.carregaPermissoes(this.storage.country_selected.id).subscribe(
            (data) => {
                this.cpgp = data.cpgp.liberado === 1
                this.mtvgp = data.cpgp.motivo
                let cks = !this.cpgp && this.tipoCPGP(this.mtvgp)
                this.authGuard.setCPGPS(cks)
                if (!this.cpgp && cks) {
                    this.isSabado = true
                }
            }
        )
    }

    // verifica se o motivo do bloqueio é o que você espera
    tipoCPGP(mtvgp) {
        switch (mtvgp) {
            case 1:
                return true
            case 2:
                return true
            default:
                return false
        }
    }

    getDomain() {
        let domain = DomainEnum.eaportal
        if (document.location.origin.match(DomainEnum.externo_br) || document.location.origin.match('si.testecpb')) {
            return DomainEnum.externo_br
        }

        return domain
    }

    private loadAnalytics() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'G-E29N9GDRHT', { 'page_path': event.urlAfterRedirects })

                    ; (<any>window).ga('edu.set', 'page', `${event.urlAfterRedirects}`)
                    ; (<any>window).ga('edu.send', 'pageview')
                    ; (<any>window).ga('ea.set', 'page', `${event.urlAfterRedirects}`)
                    ; (<any>window).ga('ea.send', 'pageview')
                    ; (<any>window).ga('nova_portal.set', 'page', `${event.urlAfterRedirects}`)
                    ; (<any>window).ga('nova_portal.send', 'pageview')
            }
        })
    }

    setTypeUser() {
        this.isUserUniao = !!this.storage.atendimento_portal || !!this.storage.funcoes.find(funcao => !!funcao.uniao_id)

        if (this.isUserUniao) {
            this.isUserCampo = true
        } else {
            this.isUserCampo = !!this.storage.atendimento_portal || !!this.storage.funcoes.find(funcao => !!funcao.campo_id)
        }
    }

    public redirect(url: string) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([url])
        );
    }

    public checkNotLinked(usuario, entities): boolean {
        if (this.storage.atendimento_portal != 1
            && usuario.entidade_responsaveis.find(responsavel => entities.find(entity => entity.id == responsavel['entidade_id'])) == undefined
            && usuario.funcoes.find(funcao => entities.find(entity => entity.id == funcao['entidade_id'])) == undefined
            && usuario.dependentes.find(dependente => entities.find(entity => entity.id == dependente['entidade_id'])) == undefined
            && usuario.escolaridade.find(escolaridade => entities.find(entity => entity.id == escolaridade['entidade_id'] && escolaridade['status'] == 1)) == undefined
            && usuario.entidades && usuario.entidades.find(entidades => entities.find(entity => entity.id == entidades.entidade['id'] && entidades.entidade['status'] == 1)) == undefined) {
            return true
        }
        return false
    }
}
