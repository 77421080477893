import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { hubConst } from '@app/core';
import { utcToZonedTime } from 'date-fns-tz';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FunctionsEnum } from '../../enums/functions.enum';
import { LoginURL } from '../../enums/login-url.enum';
import { UsuarioService } from '../services/usuario.service';
import { Sabado, Swal } from '../utils';
import { LocalStorageService } from './storage.service';
import { CountryEnum } from 'enums/country.enum';

@Injectable()
export class AuthGuard implements CanActivate {
    auth: boolean = false
    params: any
    pagina: string
    url: string
    verifica: boolean = false
    bgps: boolean = false // bloquear gerenciador portal no sábado

    api: any = hubConst.api

    public login = new EventEmitter()
    public isSabado = new EventEmitter()
    isBrasil: boolean;
    public storage: any;

    constructor(
        private storageService: LocalStorageService,
        private router: Router,
        private sabado: Sabado,
        private swal: Swal,
        private usuarioService: UsuarioService,
        private _localStorage: LocalStorageService,
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.pagina = route.routeConfig.path
        this.params = route.queryParams
        this.api.local = window.location.origin + '/'
        this.api.login = this.defineDomainLogin()
        this.api.logout = this.api.login + 'logout'
        this.url = btoa(this.api.local + this.pagina)
        this.verificaCoordenadorConteudo()
        this.verificarAcesso()
        return this.storageService.storage$.pipe(map(storage => !!storage))
    }

    private defineDomainLogin() {
        if (!!document.location.href.match('eaportal')) {
            return LoginURL.eaportal
        } else if (!!document.location.href.match('sistemainterativo')) {
            return LoginURL.sistemainterativo
        } else if (!!document.location.href.match('testecpb')) {
            return LoginURL.testecpb
        } else if (!!document.location.href.match('localhost')) {
            if(hubConst.env === "local"){
				return LoginURL.localhost
			}
			if(hubConst.env === "dev"){
				return LoginURL.testecpb
			}
        }

        return LoginURL.educacaoadventista
    }

    private redirectLogin() {
        window.location.href = this.api.login + '?l=' + this.url
        return false
    }

    private redirectLogout() {
        window.location.href = this.api.logout + '?l=' + this.url
        return false
    }

    public limpaStorage(redirect: string, user_id: number = null) {
        let seconds = 0
        if (user_id != null) {
            seconds = 1500
            this.usuarioService.expireSessionGoogle(user_id).subscribe(async (response) => { }, error => {})
        }

		this.storageService.removeStorage('usuario')

		if (redirect == 'login') setTimeout(() => this.redirectLogin(), seconds)
		else if (redirect == 'logout') setTimeout(() => this.redirectLogout(), seconds)
		else this.url = btoa(this.api.local + redirect)
		setTimeout(() => this.redirectLogout(), seconds)
    }

    public updateToken(storage, url: string = '') {
        let dataPost = {
            cpf: storage.perfil.cpf,
            dependente_padrao: storage.dependente_padrao,
            funcao_padrao: storage.funcao_padrao,
            entidade: storage.usuario_ativo.entidade,
            cod_escola: storage.usuario_ativo.cod_escola,
            ra: storage.usuario_ativo.ra,
            sistema: storage.usuario_ativo.secretaria_sistema_id,
        }
        this.storageService
            .getUpdateToken(storage.token, dataPost)
            .then((res) => {
                storage.token = res.token
                this.storageService.setStorageUsuario(storage)
                if (url != '') {
                    window.location.href = url
                } else {
                    window.location.reload()
                }
            })
            .catch((error) => { })
    }

    private async getUsuario(token, access_token?) {
        await this.storageService.getDadosUsuario(token).then(async (data: any) => {
            if (data != null) {
                data.token = token
                if (access_token != undefined) {
                    data.access_token = access_token
                }

                await this.storageService.setStorageUsuario(data)
                this.login.emit(true)
                let timezone = data.paises[0].timezone ? data.paises[0].timezone : 'America/Sao_Paulo'
                let pais_id = data.paises[0].id != null ? data.paises[0].id : 1
                let hoje = utcToZonedTime(new Date(), timezone)
                if (this.sabado.verificaSabado(hoje, pais_id)) {
                    this.setCPGPS(1)
                    this.validaSabado()
                } else {
                    this.router.navigate([`/${this.pagina}`])
                }
            }
            else {
                this.swal.button('Atenção', 'Sem permissão para acessar o sistema!', 'info', 'Ok', { callback: () => this.limpaStorage('login') })
            }
        })
        .catch((error) => {
            if (error.ok == false && error.status == 401) this.limpaStorage('login')

            return false
        })
    }

    public async verificarAcesso():Promise<boolean> {
        if (this.params != undefined && this.params.t) {
            const access_token = this.params.g != undefined ? this.params.g : null
            this.storageService.setStorageUsuario({token: this.params.t})
            await this.storageService
                .getRefreshToken(this.params.t)
                .then(async (data) => {
                    await this.getUsuario(data.token, access_token)
                })
                .catch((error) => {
                    if (error.ok == false && error.status == 500) this.pagina ? this.limpaStorage(this.pagina) : this.limpaStorage('login')
                })
        } else {
            if (!!this.storageService.storageUsuario) {
                this.login.emit(true)
                this.validaSabado()
                return true
            } else {
                this.pagina ? this.limpaStorage(this.pagina) : this.limpaStorage('login')
            }
        }
    }

    private verificaCoordenadorConteudo() {
        const routes = ['/', '']
        const storage = this.storageService.storageUsuario
        if (routes.includes(window.location.pathname) && storage.coordenador_conteudo == 1) {
            window.location.href = '/materiais-didaticos'
        }
    }

    validaSabado() {
        this.storage = this._localStorage.storageUsuario
        this.isBrasil = this.storage.country_selected.id == CountryEnum.Brasil
        if (this.pagina != 'sabado' && this.bgps && this.isBrasil) {
            this.isSabado.emit(true)
            this.router.navigate(['/sabado'])
        }
    }

    public setCPGPS(param) {
        this.bgps = param
        this.validaSabado()
    }

    validaAcesso(userFunctions): boolean {
        const functions = [
            FunctionsEnum.funcionarioDidaticos,
            FunctionsEnum.representante,
            FunctionsEnum.desenvolvedorPortal,
            FunctionsEnum.funcionarioPortal,
            FunctionsEnum.suportePortal
        ]

        let acesso = userFunctions.filter(({ funcao_id }) => functions.includes(funcao_id))
        return acesso.length > 0
    }
}
