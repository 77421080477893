import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { int_model_flip } from '@app/materiais-didaticos/modules/livros-digitais/components/flips-livro-digital/flips-livro-digital.component';
import { ApiService, Resp } from '@app/services';
import { Livro } from '@shared/models/didactic-material';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LivroService {
    public books$ = new BehaviorSubject(int_model_flip)

    private _baseUrl = hubConst.api.didaticos

    constructor(private apiService: ApiService) { }

    index(params: any): Observable<Resp<Livro[]>> {
        return this.apiService.get<Livro[]>(`${this._baseUrl}admin/v1/livro`, params)
    }

    public getbooks(entityId: number, entityType: number, year: number, bookType: number): Observable<Resp<any>> {
        return this.apiService.get(`${this._baseUrl}admin/v1/entidade-livro`, { entityId, entityType, year, bookType })
    }

    public storeBookEntity(data): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}admin/v1/entidade-livro`, data)
    }

    public getFascicles(editora_id: number): Observable<Resp<any>> {
        return this.apiService.get(`${this._baseUrl}admin/v1/fasciculos?editora_id=${editora_id}`)
    }

    public getGroupings(publishingId: number): Observable<Resp<any>> {
        return this.apiService.get(`${this._baseUrl}admin/v1/grouping-book?publishing_id=${publishingId}`)
    }

    public getBook(book_id: number): Observable<Resp<any>> {
        return this.apiService.get(`${this._baseUrl}admin/v1/livro/${book_id}`)
    }

    public getStatusRenderingBook(anexo_id: number, rota: string = "livros"): Observable<Resp<any>> {
        return this.apiService.get(`${this._baseUrl}admin/v1/${rota}/${anexo_id}/status-rendering`)
    }

    public deleteBook(book_id: number): Observable<Resp<any>> {
        return this.apiService.delete(`${this._baseUrl}admin/v1/livro/${book_id}`)
    }

    public storeBook(data): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}admin/v1/livro`, data)
    }

    public storeVinculoAcademico(data): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}admin/v1/livros/vinculo-academico`, data)
    }

    public deleteVinculoAcademico(id: number): Observable<Resp<any>> {
        return this.apiService.delete(`${this._baseUrl}admin/v1/livros/vinculo-academico/${id}`)
    }

    public deleteFlip(book_id: number): Observable<Resp<any>> {
        return this.apiService.delete(`${this._baseUrl}admin/v1/livros/${book_id}/flip`)
    }

    public updateBook(data): Observable<Resp<any>> {
        return this.apiService.put(`${this._baseUrl}admin/v1/livro/${data.id}`, data)
    }

    public updateParadidatico(id: number): Observable<Resp<any>> {
        return this.apiService.put(`${this._baseUrl}admin/v1/livro-paradidatico/${id}`)
    }

    public duplicate(bookId: number): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}admin/v1/livros/${bookId}/duplicate`)
    }

    public duplicateYear(data: any): Observable<Resp<any>> {
        return this.apiService.post(`${this._baseUrl}admin/v1/entidade-livro/duplicar-ano`, data);
    }
}
