import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { hubConst } from '@app/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UsuarioService {
    api: any = hubConst.api;

    constructor(private http: HttpClient) {}

    procuraUsuario(nome, escola_id, uniao_id, campo_id): Observable<any[]> {
        return this.http
            .get<any[]>(
                `${this.api.usuario_api}procura-nome-usuario?nome=${nome}&escola_id=${escola_id}&uniao_id=${uniao_id}&campo_id=${campo_id}`
            )
            .pipe(map((response) => response));
    }

    buscaAlunos(id, nome): Observable<any> {
        return this.http
            .get(
                `${this.api.usuario_api}procura-nome-aluno?escola_id=${id}&nome=${nome}`
            )
            .pipe(map((response) => response));
    }

    dadosDependente(id): Observable<any> {
        return this.http
            .get(`${this.api.usuario_api}dados-dependente/${id}`)
            .pipe(map((response) => response));
    }

    carregaPermissoes(pais_id = null): Observable<any> {
        let params = ''
        if (pais_id != null) {
            params = 'pais_id=' + pais_id
        }
        return this.http
            .get(`${this.api.permissao_api}permissoes/cpgp?${params}`)
            .pipe(map(response => response));
    }

    listaEscolas(): Observable<any> {
        return this.http
            .get(`${this.api.usuario_api}entidades`)
            .pipe(map((response) => response));
    }

    listaEscolasMenu(escola): Observable<any> {
        return this.http
            .get(`${this.api.usuario_api}escola-menu/${escola}`)
            .pipe(map((response) => response));
    }

    listAlunosByEntidadeAndPais(entidade: number, pais: number, query: any, qtd: number, emailFilter: number, page: number): Observable<any> {
        return this.http
            .get(
                `${this.api.usuario_api}hub-educacional/alunos?entidade=${entidade}&pais=${pais}&query=${query}&qtd=${qtd}&emailFilter=${emailFilter}&page=${page}`
            )
            .pipe(map((response) => response),
            catchError((error) => error)
            )
    }


    listProfessoresByEntidadeAndPais(entidade: number, pais: number, query: any, qtd: number, emailFilter: number, page: number, language: string = 'pt-BR'): Observable<any> {
        return this.http
        .get(
            `${this.api.usuario_api}hub-educacional/professores?entidade=${entidade}&pais=${pais}&query=${query}&qtd=${qtd}&emailFilter=${emailFilter}&page=${page}&language=${language}`
            )
            .pipe(map((response) => response),
            catchError((error) => error)
            )
    }

    listResponsaveisByEntidadeAndPais(entidade: number, pais: number, query: any, qtd: number, emailFilter: number, page: number): Observable<any> {
        return this.http
        .get(
            `${this.api.usuario_api}hub-educacional/responsaveis?entidade=${entidade}&pais=${pais}&query=${query}&qtd=${qtd}&emailFilter=${emailFilter}&page=${page}`
            )
            .pipe(map((response) => response),
            catchError((error) => error)
            )
    }

    listAdministradoresByEntidadeAndPais(entidade: number, pais: number, query: any, qtd: number, emailFilter: number, page: number, language: string = 'pt-BR'): Observable<any> {
        return this.http
        .get(
            `${this.api.usuario_api}hub-educacional/administradores?entidade=${entidade}&pais=${pais}&query=${query}&qtd=${qtd}&emailFilter=${emailFilter}&page=${page}&language=${language}`
            )
            .pipe(map((response) => response),
            catchError((error) => error)
            )
    }

    listApoioEscolarByEntidadeAndPais(entidade: number, pais: number, query: any, qtd: number, emailFilter: number, page: number, language: string = 'pt-BR'): Observable<any> {
        return this.http
            .get(
                `${this.api.usuario_api}hub-educacional/apoio?entidade=${entidade}&pais=${pais}&query=${query}&qtd=${qtd}&emailFilter=${emailFilter}&page=${page}&language=${language}`
            )
            .pipe(map((response) => response),
            catchError((error) => error)
        )
    }

    listTecnicoInformaticaByEntidadeAndPais(entidade: number, pais: number, query: any, qtd: number, emailFilter: number, page: number, language: string = 'pt-BR'): Observable<any> {
        return this.http
            .get(
                `${this.api.usuario_api}hub-educacional/ti?entidade=${entidade}&pais=${pais}&query=${query}&qtd=${qtd}&emailFilter=${emailFilter}&page=${page}&language=${language}`
            )
            .pipe(map((response) => response),
            catchError((error) => error)
        )
    }

    listSemFuncoesByEntidadeAndPais(entidade: number, pais: number, query: any, qtd: number, emailFilter: number, page: number, language: string = 'pt-BR'): Observable<any> {
        return this.http
            .get(
                `${this.api.usuario_api}hub-educacional/sem-funcoes?entidade=${entidade}&pais=${pais}&query=${query}&qtd=${qtd}&emailFilter=${emailFilter}&page=${page}&language=${language}`
            )
            .pipe(map((response) => response),
            catchError((error) => error)
        )
    }

    listUsers(entidade: number, campo: number, uniao: number, pais: number, query: any, qtd: number, emailFilter: number, gsuiteFilter: number, page: number, group: number = 0, language: string = 'pt-BR', matricula: any = 0, turma_id: any=null, statusFilter: number = null, lecionaFilter: number = null, dependentesFilter: number = null): Observable<any> {
        let params = `group=${group}&pais_id=${pais}&query=${query}&qtd=${qtd}&emailFilter=${emailFilter}&gsuiteFilter=${gsuiteFilter}&page=${page}&language=${language}&entidade=${entidade}&campo=${campo}&uniao=${uniao}&matricula=${matricula}&statusFilter=${statusFilter}&lecionaFilter=${lecionaFilter}&dependentesFilter=${dependentesFilter}`

        if(turma_id && turma_id != 0){
            params = params + `&turma_id=${turma_id}`
        }

        return this.http
            .get(
                `${this.api.usuario_api}hub-educacional/usuarios-list?${params}`
            )
            .pipe(map((response) => response),
            catchError((error) => error)
        )
    }

    checkGSuiteAvailability(gsuite, user_id?): Observable<any> {
        let params = '';
        if (user_id) {
            params = '?user_id=' + user_id
        }
        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/gsuite-availability/${gsuite}${params}`).pipe(map(response => response));
    }

    checkRAAvailability(ra, user_id?, entity_id?): Observable<any> {
        let params = '';
        if (user_id) {
            params = '?user_id=' + user_id
        }
        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/ra-availability/${ra}/${entity_id}${params}`).pipe(map(response => response));
    }

    updateUserPersonalData(data): Observable<any> {
        return this.http.put(`${this.api.usuario_api}hub-educacional/user/${data.id}/personal-data`, data).pipe(map(response => response));
    }

    updateFuncaoPadrao(user_id: number, funcao_padrao): Observable<any> {
        let data = {funcao_padrao: funcao_padrao}
        return this.http.put(`${this.api.usuario_api}hub-educacional/user/${user_id}/funcao-padrao`, data).pipe(map(response => response));
    }

    updateDependentePadrao(user_id: number, dependente_padrao): Observable<any> {
        let data = {dependente_padrao: dependente_padrao}
        return this.http.put(`${this.api.usuario_api}hub-educacional/user/${user_id}/dependente-padrao`, data).pipe(map(response => response));
    }

    checkIfUserExists(document, country, user_id?: number): Observable<any> {
        let params = ''
        if (user_id != null) {
            params += `&user_id=${user_id}`
        }
        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/user-exists/${document}/${country}?${params}`).pipe(map(response => response));
    }

    // sendConfirmationMail(user_id: number, country_id: number): Observable<any> {
    //     let params = `country_id${country_id}`
    //     return this.http.get<any>(`${this.api.usuario_api}hub-educacional/user/${user_id}/send-confirmation-mail?${params}`).pipe(map(response => response));
    // }

    sendConfirmationMail(user_id, country_id: number): Observable<any> {
        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/user/send-confirmation-mail?country_id=${country_id}`, {params: {'user_id[]': user_id}}).pipe(map(response => response));
    }

    activeAccountGoogle(user_id: number): Observable<any> {
        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/user/${user_id}/active-gsuite`).pipe(map(response => response));
    }
    
    deleteAccountGoogle(user_id: number): Observable<any> {
        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/user/${user_id}/delete-gsuite`).pipe(map(response => response));
    }

    resetPasswordAccountGoogle(user_id: number, country_id: number): Observable<any> {
        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/user/${user_id}/reset-password-gsuite?country_id=${country_id}`).pipe(map(response => response));
    }

    updateGsuiteAccountGoogle(user_id: number, gsuite: string): Observable<any> {
        return this.http.post<any>(`${this.api.usuario_api}hub-educacional/user/${user_id}/update-gsuite`, {gsuite}).pipe(map(response => response));
    }

    generateCredentials(user_id, country_id: number): Observable<any>{
        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/gera-carta-credencial?country_id=${country_id}`, {params: {'user_id[]': user_id}}).pipe(map(response => response));
    }

    emitCredentials(user_id, country_id: number, token: any){
        let array = ""
        user_id.forEach(element => {
            array += "&user_id[]=" + element
        });
        window.open(`${this.api.usuario_api}hub-educacional/emit-carta-credencial?token=${token}&country_id=${country_id}${array}`, '_blank');
    }

    getUser(user_uuid, country_id, edit: boolean = false): Observable<any> {
        return this.http.get<any>(`${this.api.usuario_api}hub-educacional/user/${user_uuid}?country_id=${country_id}&edit=${edit}`).pipe(map(response => response));
    }

    getEmployee(user_id, country_id: number, edit:boolean = false): Observable<any> {
        return this.http.get(`${this.api.usuario_api}hub-educacional/user/${user_id}/funcionario?edit=${edit}&country_id=${country_id}`).pipe(map(response => response));
    }

    getResponsable(user_id, country_id: number, edit:boolean = false): Observable<any> {
        return this.http.get(`${this.api.usuario_api}hub-educacional/user/${user_id}/responsavel?edit=${edit}&country_id=${country_id}`).pipe(map(response => response));
    }

    getStudent(user_id, country_id: number, edit:boolean = false): Observable<any> {
        return this.http.get(`${this.api.usuario_api}hub-educacional/user/${user_id}/aluno?edit=${edit}&country_id=${country_id}`).pipe(map(response => response));
    }

    getEntities(country_id, all: boolean = false, withoutExternal: boolean = false): Observable<any> {
        let params = `country_id=${country_id}`
        if (all) {
            params += `&all=${all}`
        }
        if (withoutExternal) {
            params += `&without_external=true`
        }

        return this.http.get(`${this.api.usuario_api}hub-educacional/user/entities?${params}`).pipe(map(response => response));
    }

    getEntitiesAdems(): Observable<any> {
        return this.http.get(`${this.api.usuario_api}hub-educacional/entities-adems`).pipe(map(response => response));
    }

    getUnioes(country_id): Observable<any> {
        return this.http.get(`${this.api.usuario_api}hub-educacional/user/unioes?country_id=${country_id}`).pipe(map(response => response));
    }

    getUnioesHispanos(): Observable<any> {
        return this.http.get(`${this.api.usuario_api}hub-educacional/unioes/hispanos`).pipe(map(response => response));
    }

    getCampos(country_id, uniao_id?): Observable<any> {
        let params = `country_id=${country_id}`
        if (uniao_id) {
            params += `&uniao_id=${uniao_id}`
        }
        return this.http.get(`${this.api.usuario_api}hub-educacional/user/campos?${params}`).pipe(map(response => response));
    }

    getUserGroup(type:number = 1, language: string = 'pt-BR') {
        return this.http
            .get<any>(
                `${this.api.usuario_api}hub-educacional/grupo-usuarios?type=${type}&language=${language}`
            )
            .pipe(map((response) => response)
        )
    }

    public getUserGroupFunctions(entityId: number, tipoEntidade: number, full: number): Observable<any> {
        const url = `${this.api.usuario_api}hub-educacional/grupo-usuarios-funcoes?entity_id=${entityId}&tipo_entidade=${tipoEntidade}&full=${full}`
        return this.http.get(url)
    }

    getFunctions() {
        return this.http.get<any>(`${this.api.usuario_api}funcoes`)
    }

    deleteCachedAdm() {
        return this.http
            .get(
                `${this.api.usuario_api}hub-educacional/administradores-delete-cache`
            )
    }

    deleteCachedTI() {
        return this.http
            .get(
                `${this.api.usuario_api}hub-educacional/ti-delete-cache`
            )
    }

    deleteCachedApoio() {
        return this.http
            .get(
                `${this.api.usuario_api}hub-educacional/apoio-delete-cache`
            )
    }

    deleteCachedAluno() {
        return this.http
            .get(
                `${this.api.usuario_api}hub-educacional/alunos-delete-cache`
            )
    }

    loginFromUser(data: any) {
        return this.http
          .post<any>(`${this.api.login_api}login-from-user`, data)
          .toPromise()
          .then(res => {
            return res
          })
    }

    expireSessionGoogle(user_id:number) {
        return this.http.get<any>(`${this.api.usuario_api}google/${user_id}/expire-session`).pipe(map((response) => response))
    }

    getListOfDevicesGoogle(user_id): Observable<any> {
		return this.http.get(`${this.api.usuario_api}google/${user_id}/devices`).pipe(map((response) => response))
	}

    getAnos(aluno_id: number): Observable<any> {
        let params = `aluno_id=${aluno_id}`
        return this.http.get(`${this.api.usuario_api}hub-educacional/anosByAluno?${params}`).pipe(map(response => response));
    }

    public getAlunoVoucher(aluno_id: number): Observable<any> {
        return this.http.get(`${this.api.usuario_api}hub-educacional/vouchers-do-aluno/${aluno_id}`);
    }

    setRecovery(data: any): Observable<any> {
        return this.http
        .post<any>(`${this.api.usuario_api}hub-educacional/responsaveis/set-recovery`, data)
        .pipe(map(response => response))
    }

    getAdesaoUsuarios(entidade_id: number, year: number): Observable<any> {
        return this.http.get(`${this.api.usuario_api}hub-educacional/relatorios/adesao-usuarios/${entidade_id}/${year}`);
    }

    public userProfilePhotoUpdate(image: File, width: string, height: string, usuario_id: number): Observable<any> {
        const formData = new FormData();

        formData.append('file', image);
        formData.set('savePhoto', 'true');
        formData.set('largura', width);
        formData.set('altura', height);
        formData.set('limiteMaximo', 'true');
        formData.set('stringKbMb', 'mb');

        return this.http.post(`${hubConst.api.usuario_api}foto-perfil?usuario_id=${usuario_id}`, formData);
    }

    offTwoStepVerification(user_id: number): Observable <any> {
        return this.http.get(`${this.api.usuario_api}hub-educacional/user/${user_id}/two-step-verification`);
    }

    recoveryAccountGoogle(user_id: number): Observable <any> {
        return this.http.get(`${this.api.usuario_api}hub-educacional/user/${user_id}/recovery-account`);
    }

    relatorioAlunosSerie(uniao_id = null): Observable<any> {
        let params = uniao_id ? `?uniao_id=${uniao_id}` : ''
        return this.http.get(`${this.api.usuario_api}hub-educacional/relatorios/alunos/serie${params}`);
    }

    relatorioAlunosNivel(uniao_id = null): Observable<any> {
        let params = uniao_id ? `?uniao_id=${uniao_id}` : ''
        return this.http.get(`${this.api.usuario_api}hub-educacional/relatorios/alunos/nivel${params}`);
    }
}
